import {
  options
} from 'less'
import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // 登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  // 首页
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
    children: []
  },
  // 游戏大厅
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    children: []
  },
  // 排行榜列表
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('../views/ranking.vue'),
    children: []
  },
  // 好友列表
  {
    path: '/friends',
    name: 'friends',
    component: () => import('../views/friends.vue'),
    children: []
  },
  // 设置弹窗
  {
    path: '/set',
    name: 'set',
    component: () => import('../views/set.vue'),
    children: []
  },
  // 个人中心
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user.vue'),
    children: []
  },
  // 弹幕
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/notice.vue'),
    children: []
  },
  // 开始游戏
  {
    path: '/startgame',
    name: 'startgame',
    component: () => import('../views/startgame.vue'),
    children: []
  },
  // 在线课堂
  {
    path: '/class',
    name: 'class',
    component: () => import('../views/class.vue'),
    children: []
  },
  // 创建者
  {
    path: '/creator',
    name: 'creator',
    component: () => import('../views/creator.vue'),
    children: []
  },
  // 参与者
  {
    path: '/participant',
    name: 'participant',
    component: () => import('../views/participant.vue'),
    children: []
  },
  // 在线课堂 房间列表
  {
    path: '/classRoom',
    name: 'classRoom',
    component: () => import('../views/classRoom.vue'),
    children: [],
    // props: true // 如果props设置为true，$route.params将被设置为组件属性
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  //  判断登录状态
  let token = localStorage.getItem('token')
  // let token = false;
  if (to.path == '/') {
    if (token) {
      next('/index');
    } else {
      next('/Login');
    }
    return;
  }
  next()
})
export default router
