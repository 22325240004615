import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './plugins/style.css'
import '../reset.css'
import ElementUI from 'element-ui';
import * as echarts from 'echarts'
import {VueJsonp} from 'vue-jsonp'
import DOMPurify from 'dompurify';
Vue.prototype.$dompurify = DOMPurify;
Vue.use(VueJsonp)
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$ws = "wss://push.local.18movecar.com"
// Vue.prototype.$baseUrlCocos = "https://htgame.local.18movecar.com/web-mobile?"
Vue.prototype.$baseUrlCocos = "https://game.local.18movecar.com/wechat-game/?"
Vue.prototype.$baseUrlImg = "https://hongtujk.oss-cn-beijing.aliyuncs.com/hongtu_pc/assets/"
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
window.addEventListener('popstate', function() {
  history.pushState(null, null, document.URL)
})

