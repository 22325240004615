<template>
  <div id="app">
    <div id="nav">
      <router-link to="/"></router-link>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
};
</script>

<style lang="less">
</style>
